import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import ContactUsSection from "../../components/sections/ContactUsSection"
import GoogleImage from "../../components/GoogleImage"
import DownloadButton from "../../components/buttons/DownloadButton"
import Layout2Columns from "../../components/layout/Layout2Columns";

import { devices } from "../../settings"

const PermitGrid = styled.div`
  img {
    border-radius: 5px;
    
    @media ${devices.mobile} {
      width: 100%;
      margin-top: 20px;
    }     
  }
`

const PermitsPage = () => (
  <SupportPage page="permits" title="Free Permit Drawings and Help">
      <PageHeader title="Sign Permit Support &amp; Drawings" />
      <Layout2Columns>
        <div>
          <p>
            SignMonkey.com will provide permit drawings for any products that are to be purchased from us. Please request 
            Permit Drawings prior to placing your order. Once the order is placed the product cannot be returned if 
            permitting is not successful.
          </p>

          <br />
          <DownloadButton 
            to={"/documents/permit-guide"}
            imageSrc="Documents/permit-guide.jpg"
            label="Sign Permit Guide"
          />
        </div>
        <PermitGrid>
          <GoogleImage src="ComputerEZLitPermit.jpg" required lazy alt="SignMonkey Computer EZLit Permit" width={isMobile ? 325:450} height={isMobile ? 220:305} />
        </PermitGrid>
      </Layout2Columns>

      <p>
        We are happy to advise and consult you on sign permitting.   
      </p>
      <ContactUsSection />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
  </SupportPage>
)

export default PermitsPage
