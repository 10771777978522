import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import GoogleImage from "../GoogleImage"
import { storage, colors } from "../../settings"

const Button = styled.button`
    display: inline-block;
    background-color: white;
    border: 1px solid ${colors.blue};
    border-radius: 5px; 
    padding: 5px 15px 5px 5px;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.025);
        box-shadow: 0 5px 5px rgba(0,0,0,0.3);
    }

    img {
        height: 45px;
        margin-right: 5px;
        font-size: 120%;
    }
`

function DownloadButton(props) {
    return (
        <>
            {props.to ? 
                <Link to={props.to}>
                    <Button>
                        <GoogleImage required lazy src={props.imageSrc} width={35} height={45} />
                        {props.label}
                    </Button>
                </Link>
            :
                <a href={storage.url + props.url} target="_blank">
                    <Button>
                        <GoogleImage required lazy src={props.imageSrc} width={35} height={45} />
                        {props.label}
                    </Button>
                </a>
            }
        </>
    );
}

export default DownloadButton