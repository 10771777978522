import React from "react"
import styled from 'styled-components'

const Section = styled.table`    
  margin-top: 25px;
  font-size: 20px;

  th { 
    font-weight: 500; 
    padding: 3px 8px;
    vertical-align: top;
  }
  td {
    padding: 3px 8px;

    div {
      font-size: 90%;
    }
  }
`

function ContactUsSection(props) {
    return (
      <Section>
        <tbody>
          <tr>
            <th>Email:</th>
            <td><a href="mailto:support@signmonkey.com">support@signmonkey.com</a></td>
          </tr>
          <tr>
            <th>Phone:</th>
            <td>
              <div>(800) 863-0107</div>
              <div>(304) 399-3503</div>                
              <div>
                Live Support Monday-Friday<br />
                8:30am to 5:00pm EST
              </div>
            </td>
          </tr>
        </tbody>
      </Section>
    );
}

export default ContactUsSection